/* global BigInt */
import React, { useState, useEffect } from 'react';
import { Card, Typography, Button, TextField, Select, MenuItem, FormControl, Dialog, DialogTitle, DialogContent, DialogActions, Avatar, Box, InputAdornment, CircularProgress, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import html2canvas from 'html2canvas';
import 'chart.js/auto';

const BetCard = styled(Card)(({ theme }) => ({
  border: '3px solid black',
  padding: '20px',
  margin: '20px',
  textAlign: 'center',
  fontFamily: "'Schoolbell', cursive",
  boxShadow: '8px 8px 0px #000',
  backgroundColor: '#FFFFFF',
  borderRadius: '20px',
  width: '100%',
  maxWidth: '350px',
  minHeight: '500px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '@media (min-width: 960px)': {
    width: '300px',
    margin: '20px',
  },
  '@media (max-width: 600px)': {
    padding: '15px',
    width: '90%',
    margin: '15px auto',
  },
}));

const HeaderTypography = styled(Typography)({
  fontFamily: "'Schoolbell', cursive",
  color: '#333',
  fontSize: '1.8rem',
  marginBottom: '10px',
  textShadow: '2px 2px 4px #FFF',
});

const StyledButton = styled(Button)(({ disabled }) => ({
    backgroundColor: disabled ? '#B0B0B0' : '#FF6347',
    color: '#FFFFFF',
    border: '3px solid black',
    fontFamily: "'Schoolbell', cursive",
    padding: '4px 6px', // Reduced padding for smaller buttons
    minWidth: '70px', // Reduced width
    '&:hover': {
      backgroundColor: disabled ? '#B0B0B0' : '#FFA500',
      color: disabled ? '#FFFFFF' : '#000',
    },
    boxShadow: '4px 4px 0px #000',
    borderRadius: '10px',
    fontSize: '0.9rem', // Slightly smaller font size
}));

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '20px',
});

const StyledFormControl = styled(FormControl)({
  width: '100%',
  fontFamily: "'Schoolbell', cursive",
  marginTop: '15px',
  '& .MuiSelect-select': {
    padding: '12px',
    backgroundColor: '#FFF',
    borderRadius: '10px',
    border: '2px solid black',
    boxShadow: '4px 4px 0px #000',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
});

const StyledTextField = styled(TextField)({
  width: '100%',
  fontFamily: "'Schoolbell', cursive",
  marginTop: '15px',
  '& .MuiInputBase-input': {
    fontFamily: "'Schoolbell', cursive",
    padding: '12px',
    backgroundColor: '#FFF',
    borderRadius: '10px',
    border: '2px solid black',
    boxShadow: '4px 4px 0px #000',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
});

const InfoDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    border: '3px solid black',
    boxShadow: '6px 6px 0px #000',
    padding: '30px',
    fontFamily: "'Schoolbell', cursive",
    borderRadius: '20px',
  },
  '& .MuiDialogTitle-root': {
    fontSize: '2rem',
    color: '#000',
    textShadow: 'none',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  '& .MuiDialogContent-root': {
    color: '#000',
    fontFamily: "'Schoolbell', cursive",
    textAlign: 'left',
  },
  '& .MuiDialogActions-root': {
    justifyContent: 'flex-end',
  },
});

const PopupDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    border: '3px solid black',
    boxShadow: '6px 6px 0px #000',
    padding: '30px',
    fontFamily: "'Schoolbell', cursive",
    borderRadius: '20px',
    position: 'relative',
    width: '100%',
    maxWidth: '500px',
  },
  '& .MuiDialogTitle-root': {
    fontSize: '2rem',
    color: '#000',
    textShadow: 'none',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  '& .MuiDialogContent-root': {
    color: '#000',
    fontFamily: "'Schoolbell', cursive",
    textAlign: 'left',
    position: 'relative',
  },
  '& .MuiDialogActions-root': {
    justifyContent: 'flex-end',
  },
  '& img': {
    position: 'absolute',
    top: '10px',
    right: '10px',
    width: '80px',
    height: '80px',
    border: '3px solid black',
    borderRadius: '50%',
    boxShadow: '4px 4px 0px #000',
  },
});

const Timer = ({ endTime }) => {
  const [timeLeft, setTimeLeft] = useState(Math.max(0, endTime - Date.now()));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(Math.max(0, endTime - Date.now()));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [endTime]);

  const formatTime = (ms) => {
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / 1000 / 60) % 60);
    const hours = Math.floor((ms / 1000 / 60 / 60) % 24);
    const days = Math.floor(ms / 1000 / 60 / 60 / 24);
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  return <Typography style={{ fontFamily: "'Schoolbell', cursive", color: '#FF6347', fontSize: '1rem' }}>{formatTime(timeLeft)}</Typography>;
};

const PlaceBet = ({ bet, lucid }) => {
  const walletAddress = bet.walletAddress;
  const [betSelections, setBetSelections] = useState({
    selectedAsset: '',
    amount: '',
    selectedResult: ''
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openGraphDialog, setOpenGraphDialog] = useState(false); // New state for graph dialog
  const [popupDetails, setPopupDetails] = useState({});
  const [currentBetInfo, setCurrentBetInfo] = useState(null);
  const [walletBalances, setWalletBalances] = useState({ ADA: 0, SNEK: 0, ERMO: 0, USDM: 0 });
  const [betResults, setBetResults] = useState({});
  const [transactionData, setTransactionData] = useState('');
  const [loading, setLoading] = useState(false);
  const [resultStats, setResultStats] = useState({});
  const [showMetadata, setShowMetadata] = useState(false);
  const [selectedChartAsset, setSelectedChartAsset] = useState('ADA');
  const [multiplier, setMultiplier] = useState(1);

  useEffect(() => {
    const fetchBalances = async () => {
      if (!walletAddress) {
        console.error('Wallet address is null or undefined.');
        return;
      }

      try {
        setLoading(true);
        const response = await axios.get(`https://cardano-mainnet.blockfrost.io/api/v0/addresses/${walletAddress}`, {
          headers: {
            project_id: 'mainnetKsDvlA00TQW8E0FVTYIto5WWz93lS5Xe'
          }
        });
        const balances = response.data.amount.reduce((acc, asset) => {
          if (asset.unit === 'lovelace') {
            acc.ADA = Math.floor(parseInt(asset.quantity) / 1000000);
          } else if (asset.unit === '279c909f348e533da5808898f87f9a14bb2c3dfbbacccd631d927a3f534e454b') {
            acc.SNEK = parseInt(asset.quantity);
          } else if (asset.unit === 'e1a212122b07460a739af69751bd01cd03174791f778b2365cc0b8f645524d4f') {
            acc.ERMO = parseInt(asset.quantity);
          } else if (asset.unit === 'c48cbb3d5e57ed56e276bc45f99ab39abe94e6cd7ac39fb402da47ad0014df105553444d') {
            acc.USDM = parseInt(asset.quantity);
          }
          return acc;
        }, { ADA: 0, SNEK: 0, ERMO: 0, USDM: 0 });
        setWalletBalances(balances);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching wallet balances:', error);
        setLoading(false);
      }
    };

    fetchBalances();
  }, [walletAddress]);

  useEffect(() => {
    const fetchTransactionsWithMetadata = async (walletAddress) => {
      try {
        const txResponse = await axios.get(`https://cardano-mainnet.blockfrost.io/api/v0/addresses/${walletAddress}/transactions`, {
          headers: {
            project_id: 'mainnetKsDvlA00TQW8E0FVTYIto5WWz93lS5Xe'
          }
        });
        const txData = txResponse.data;
        let txDetails = '';
        const resultStatistics = {};

        for (let tx of txData) {
          const txMetadataResponse = await axios.get(`https://cardano-mainnet.blockfrost.io/api/v0/txs/${tx.tx_hash}/metadata`, {
            headers: {
              project_id: 'mainnetKsDvlA00TQW8E0FVTYIto5WWz93lS5Xe'
            }
          });

          const metadata = txMetadataResponse.data;
          if (metadata.length > 0) {
            const result = metadata[0].json_metadata.result;

            if (!resultStatistics[result]) {
              resultStatistics[result] = {
                ADA: { count: 0, total: 0 },
                SNEK: { count: 0, total: 0 },
                ERMO: { count: 0, total: 0 },
                USDM: { count: 0, total: 0 },
              };
            }

            const txUtxoResponse = await axios.get(`https://cardano-mainnet.blockfrost.io/api/v0/txs/${tx.tx_hash}/utxos`, {
              headers: {
                project_id: 'mainnetKsDvlA00TQW8E0FVTYIto5WWz93lS5Xe'
              }
            });
            const utxoData = txUtxoResponse.data;
            let bettedBalances = { ADA: 0, SNEK: 0, ERMO: 0, USDM: 0 };

            utxoData.outputs.forEach(output => {
              if (output.address === walletAddress) {
                output.amount.forEach(asset => {
                  if (asset.unit === 'lovelace') {
                    bettedBalances.ADA += parseInt(asset.quantity, 10) / 1000000;
                  } else if (asset.unit === '279c909f348e533da5808898f87f9a14bb2c3dfbbacccd631d927a3f534e454b') {
                    bettedBalances.SNEK += parseInt(asset.quantity, 10);
                  } else if (asset.unit === 'e1a212122b07460a739af69751bd01cd03174791f778b2365cc0b8f645524d4f') {
                    bettedBalances.ERMO += parseInt(asset.quantity, 10);
                  } else if (asset.unit === 'c48cbb3d5e57ed56e276bc45f99ab39abe94e6cd7ac39fb402da47ad0014df105553444d') {
                    bettedBalances.USDM += parseInt(asset.quantity, 10);
                  }
                });
              }
            });

            if (bettedBalances.ERMO > 0 || bettedBalances.SNEK > 0 || bettedBalances.USDM > 0) {
              bettedBalances.ADA = 0;
            }
            if (bettedBalances.ADA > 0) {
              resultStatistics[result].ADA.count += 1;
              resultStatistics[result].ADA.total += bettedBalances.ADA;
            }
            if (bettedBalances.SNEK > 0) {
              resultStatistics[result].SNEK.count += 1;
              resultStatistics[result].SNEK.total += bettedBalances.SNEK;
            }
            if (bettedBalances.ERMO > 0) {
              resultStatistics[result].ERMO.count += 1;
              resultStatistics[result].ERMO.total += bettedBalances.ERMO;
            }
            if (bettedBalances.USDM > 0) {
              resultStatistics[result].USDM.count += 1;
              resultStatistics[result].USDM.total += bettedBalances.USDM;
            }

            if (showMetadata) {
              txDetails += `<div><strong>Transaction Hash:</strong> ${tx.tx_hash}<br>
                            <strong>Result:</strong> ${metadata[0].json_metadata.result}<br>
                            <strong>Betted Balances:</strong><br>
                            ${bettedBalances.ADA > 0 ? `ADA: ${bettedBalances.ADA.toFixed(2)}<br>` : ''}
                            ${bettedBalances.SNEK > 0 ? `SNEK: ${bettedBalances.SNEK}<br>` : ''}
                            ${bettedBalances.ERMO > 0 ? `ERMO: ${bettedBalances.ERMO}<br>` : ''}
                            ${bettedBalances.USDM > 0 ? `USDM: ${bettedBalances.USDM}<br>` : ''}
                            </div>`;
            }
          }
        }

        Object.keys(resultStatistics).forEach(result => {
          if (resultStatistics[result].SNEK.count === 0) {
            resultStatistics[result].SNEK.total = 0;
          }
          if (resultStatistics[result].ERMO.count === 0) {
            resultStatistics[result].ERMO.total = 0;
          }
          if (resultStatistics[result].USDM.count === 0) {
            resultStatistics[result].USDM.total = 0;
          }
        });

        setResultStats(resultStatistics);
        setTransactionData(txDetails);
      } catch (error) {
        console.error('Error fetching transactions with metadata:', error);
      }
    };

    if (walletAddress) {
      fetchTransactionsWithMetadata(walletAddress);
    }
  }, [walletAddress, bet.id, showMetadata]);

  useEffect(() => {
    const calculateMultiplier = () => {
      const { selectedAsset, selectedResult } = betSelections;
      if (selectedAsset && selectedResult && resultStats[selectedResult]) {
        let selectedBet = resultStats[selectedResult][selectedAsset]?.total || 0;

        // Treat 0 bet as 1 for maximum multiplier
        if (selectedBet === 0) {
          selectedBet = 1;
        }

        // Calculate the total bet across all results for the selected asset
        const totalBetAcrossResults = Object.values(resultStats).reduce((sum, resultData) => {
          return sum + (resultData[selectedAsset]?.total || 0);
        }, 0);

        const totalBetWithMinOne = totalBetAcrossResults > 0 ? totalBetAcrossResults : 1;

        // Calculate the multiplier
        const multiplier = (totalBetWithMinOne / selectedBet) * 0.97; // Apply the 3% decrease
        setMultiplier(multiplier.toFixed(2));
      } else {
        setMultiplier(1);
      }
    };

    calculateMultiplier();
  }, [betSelections, resultStats]);

  const handleSelectionChange = (key, value) => {
    setBetSelections((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handlePlaceBet = async () => {
    const { selectedAsset, amount, selectedResult } = betSelections;
    if (!lucid || !walletAddress) {
      alert('Please connect your wallet first.');
      return;
    }

    try {
      let assetPolicy = '';
      let assetNameHex = '';
      let paymentValue = {};

      if (selectedAsset === 'ADA') {
        paymentValue = { lovelace: BigInt(amount * 1000000) };
      } else if (selectedAsset === 'SNEK') {
        assetPolicy = '279c909f348e533da5808898f87f9a14bb2c3dfbbacccd631d927a3f';
        assetNameHex = '534e454b'; 
      } else if (selectedAsset === 'ERMO') {
        assetPolicy = 'e1a212122b07460a739af69751bd01cd03174791f778b2365cc0b8f6';
        assetNameHex = '45524d4f'; 
      } else if (selectedAsset === 'USDM') {
        assetPolicy = 'c48cbb3d5e57ed56e276bc45f99ab39abe94e6cd7ac39fb402da47ad';
        assetNameHex = '0014df105553444d'; 
      }

      if (assetPolicy && assetNameHex) {
        paymentValue = { [`${assetPolicy}${assetNameHex}`]: BigInt(amount) };
      }

      const tx = await lucid
        .newTx()
        .payToAddress(
          'addr1q8z5ht3zyralln2jer20ct9u2cu8gdtd20cc0j59l2sz2ncrm00kzlaetdrhccdmvucnlnqsn88cyz3p495hsuzs0h7sc7t0eg',
          paymentValue
        )
        .attachMetadata(1, { betId: bet.id, result: selectedResult })
        .complete();

      const signedTx = await tx.sign().complete();
      const txHash = await signedTx.submit();
      console.log('Transaction submitted:', txHash);

      setPopupDetails({
        txHash,
        amount,
        selectedAsset,
        multiplier,
      });
      setOpenPopup(true);
    } catch (error) {
      console.error('Error placing bet:', error.message);
    }
  };

  const handleDownload = () => {
    const element = document.getElementById('popup-details');
    html2canvas(element, { backgroundColor: '#fff' }).then((canvas) => {
      const context = canvas.getContext('2d');
      context.strokeStyle = '#FF6347';
      context.lineWidth = 20;
      context.strokeRect(0, 0, canvas.width, canvas.height);

      const frameWidth = 30;
      context.fillStyle = '#FFE4B5';
      context.fillRect(frameWidth, frameWidth, canvas.width - 2 * frameWidth, canvas.height - 2 * frameWidth);

      const link = document.createElement('a');
      link.download = 'transaction-details.jpg';
      link.href = canvas.toDataURL('image/jpeg');
      link.click();
    });
  };

  const assetOptions = ['ADA', 'ERMO', 'SNEK', 'USDM'];

  const handleInfoClick = () => {
    setCurrentBetInfo(bet);
    setOpenDialog(true);
  };

  const handleGraphClick = () => { // New handler for graph button
    setOpenGraphDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentBetInfo(null);
  };

  const handleCloseGraphDialog = () => { // Close graph dialog
    setOpenGraphDialog(false);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleMetadataToggle = (event) => {
    setShowMetadata(event.target.checked);
  };

  const handleChartAssetChange = (event) => {
    setSelectedChartAsset(event.target.value);
  };

  const chartData = {
    labels: Object.keys(resultStats),
    datasets: [
      {
        label: `${selectedChartAsset} Transactions`,
        data: Object.keys(resultStats).map((result) => resultStats[result][selectedChartAsset]?.total || 0),
        backgroundColor: {
          ADA: 'blue',
          ERMO: 'red',
          SNEK: 'purple',
          USDM: 'green'
        }[selectedChartAsset],
        borderColor: '#000',
        borderWidth: 2,
        borderRadius: 10,
      },
    ],
  };

  return (
    <BetCard>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Avatar
          alt="Profile Picture"
          src={bet.profilePicture}
          sx={{ width: 50, height: 50, marginRight: '10px' }}
        />
        <HeaderTypography variant="h6">{`${bet.type} - ${bet.title}`}</HeaderTypography>
      </Box>

      <Box height="2px" bgcolor="#000" margin="10px 0"></Box>

      <Typography variant="body1" style={{ fontFamily: "'Schoolbell', cursive", color: '#333', fontSize: '1rem' }}>
        {bet.description}
      </Typography>

      <Box marginTop="15px">
        <Box component="div" style={{ fontFamily: "'Schoolbell', cursive" }}>Time left: <Timer endTime={new Date(bet.endDate).getTime()} /></Box>
      </Box>

      <Box marginTop="10px">
        <Typography variant="body2" style={{ fontFamily: "'Schoolbell', cursive", color: '#FF6347', fontSize: '1rem' }}>
          Multiplier: x{multiplier}
        </Typography>
      </Box>

      <Box height="2px" bgcolor="#000" margin="15px 0"></Box>

      <StyledFormControl margin="normal">
        <Select
          value={betSelections.selectedAsset}
          onChange={(e) => handleSelectionChange('selectedAsset', e.target.value)}
          displayEmpty
        >
          <MenuItem value="" disabled>Select Asset</MenuItem>
          {assetOptions.map((asset) => (
            <MenuItem key={asset} value={asset}>{asset}</MenuItem>
          ))}
        </Select>
      </StyledFormControl>

      <StyledTextField
        type="number"
        margin="normal"
        value={betSelections.amount}
        onChange={(e) => handleSelectionChange('amount', e.target.value)}
        inputProps={{ min: '0' }}
        placeholder="Enter amount"
        InputProps={{
          endAdornment: <InputAdornment position="end">{betSelections.selectedAsset}</InputAdornment>,
        }}
      />

      <StyledFormControl margin="normal">
        <Select
          value={betSelections.selectedResult}
          onChange={(e) => handleSelectionChange('selectedResult', e.target.value)}
          displayEmpty
        >
          <MenuItem value="" disabled>Select Result</MenuItem>
          {bet.result.map((result) => (
            <MenuItem key={result} value={result}>{result}</MenuItem>
          ))}
        </Select>
      </StyledFormControl>

      <Box marginTop="10px">
        <Typography variant="body2" style={{ fontFamily: "'Schoolbell', cursive", color: '#333', fontSize: '1rem' }}>
          At current rates, you will get {(betSelections.amount * multiplier).toFixed(2)} {betSelections.selectedAsset}
        </Typography>
      </Box>

      <ButtonContainer>
        <StyledButton onClick={handlePlaceBet} disabled={new Date(bet.endDate).getTime() <= Date.now()}>
          Place Bet
        </StyledButton>
        <StyledButton onClick={handleInfoClick}>Info</StyledButton>
        <StyledButton onClick={handleGraphClick}>GRAPH</StyledButton> {/* New Graph button */}
      </ButtonContainer>

      {currentBetInfo && (
        <InfoDialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{currentBetInfo.title} - Details</DialogTitle>
          <DialogContent>
            <Typography variant="body1" style={{ fontFamily: "'Schoolbell', cursive" }}><strong>Description:</strong> {currentBetInfo.description}</Typography>
            <Typography variant="body1" style={{ fontFamily: "'Schoolbell', cursive", marginTop: '10px' }}><strong>Available Results:</strong> {currentBetInfo.result.join(', ')}</Typography>
            <Typography variant="body1" style={{ fontFamily: "'Schoolbell', cursive", marginTop: '10px' }}><strong>Bet Balance:</strong></Typography>
            <Typography variant="body2" style={{ fontFamily: "'Schoolbell', cursive" }}>ADA: {walletBalances.ADA}</Typography>
            <Typography variant="body2" style={{ fontFamily: "'Schoolbell', cursive" }}>SNEK: {walletBalances.SNEK}</Typography>
            <Typography variant="body2" style={{ fontFamily: "'Schoolbell', cursive" }}>ERMO: {walletBalances.ERMO}</Typography>
            <Typography variant="body2" style={{ fontFamily: "'Schoolbell', cursive" }}>USDM: {walletBalances.USDM}</Typography>
            <Box height="2px" bgcolor="#000" margin="15px 0"></Box>
            <Box marginTop="20px">
              {loading ? <CircularProgress /> : <Box dangerouslySetInnerHTML={{ __html: transactionData }} />}
              <Box marginTop="20px">
                <Typography variant="body2" style={{ fontFamily: "'Schoolbell', cursive", color: '#000', fontSize: '1rem', marginBottom: '10px' }}>
                  <strong>Result Statistics:</strong>
                </Typography>
                <Grid container spacing={2} style={{ fontFamily: "'Schoolbell', cursive" }}>
                  {Object.entries(resultStats).map(([result, assets]) => (
                    <React.Fragment key={result}>
                      <Grid item xs={3}>
                        <Typography variant="body2" style={{ fontFamily: "'Schoolbell', cursive" }}><strong>{result}</strong></Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="body2" style={{ fontFamily: "'Schoolbell', cursive" }}>ADA: {assets.ADA.total.toFixed(2)}</Typography>
                        <Typography variant="body2" style={{ fontFamily: "'Schoolbell', cursive" }}>SNEK: {assets.SNEK.total}</Typography>
                        <Typography variant="body2" style={{ fontFamily: "'Schoolbell', cursive" }}>ERMO: {assets.ERMO.total}</Typography>
                        <Typography variant="body2" style={{ fontFamily: "'Schoolbell', cursive" }}>USDM: {assets.USDM.total}</Typography>
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
                <StyledFormControl margin="normal">
                  <Select
                    value={selectedChartAsset}
                    onChange={handleChartAssetChange}
                    displayEmpty
                    sx={{
                      fontFamily: "'Schoolbell', cursive",
                      backgroundColor: '#FFF',
                      borderRadius: '10px',
                      border: '2px solid black',
                      boxShadow: '4px 4px 0px #000',
                      marginBottom: '20px',
                    }}
                  >
                    <MenuItem value="" disabled>Select Asset for Chart</MenuItem>
                    {assetOptions.map((asset) => (
                      <MenuItem key={asset} value={asset}>{asset}</MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
                <Box sx={{
                  width: '100%',
                  height: '300px',
                  overflowX: 'hidden',
                }}>
                  <Bar data={chartData} options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        labels: {
                          font: {
                            family: "'Schoolbell', cursive",
                            size: 16,
                          },
                        },
                      },
                    },
                    scales: {
                      x: {
                        ticks: {
                          font: {
                            family: "'Schoolbell', cursive",
                            size: 14,
                          },
                        },
                      },
                      y: {
                        ticks: {
                          font: {
                            family: "'Schoolbell', cursive",
                            size: 14,
                          },
                        },
                      },
                    },
                  }} />
                </Box>
                <Box height="2px" bgcolor="#000" margin="10px 0"></Box>
              </Box>
              <FormControlLabel
                control={<Checkbox checked={showMetadata} onChange={handleMetadataToggle} />}
                label="Show Metadata (BETA)"
                style={{ marginTop: '20px', fontFamily: "'Schoolbell', cursive" }}
              />
              {showMetadata && (
                <Box marginTop="20px" dangerouslySetInnerHTML={{ __html: transactionData }} />
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <StyledButton onClick={handleCloseDialog}>Close</StyledButton>
          </DialogActions>
        </InfoDialog>
      )}

      {/* New Graph Dialog */}
      <InfoDialog open={openGraphDialog} onClose={handleCloseGraphDialog}>
        <DialogTitle>Graph</DialogTitle>
        <DialogContent>
          <StyledFormControl margin="normal">
            <Select
              value={selectedChartAsset}
              onChange={handleChartAssetChange}
              displayEmpty
              sx={{
                fontFamily: "'Schoolbell', cursive",
                backgroundColor: '#FFF',
                borderRadius: '10px',
                border: '2px solid black',
                boxShadow: '4px 4px 0px #000',
                marginBottom: '20px',
              }}
            >
              <MenuItem value="" disabled>Select Asset for Chart</MenuItem>
              {assetOptions.map((asset) => (
                <MenuItem key={asset} value={asset}>{asset}</MenuItem>
              ))}
            </Select>
          </StyledFormControl>
          <Box sx={{
            width: '100%',
            height: '400px', // Larger height for the graph
            overflowX: 'hidden',
          }}>
            <Bar data={chartData} options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  labels: {
                    font: {
                      family: "'Schoolbell', cursive",
                      size: 16,
                    },
                  },
                },
              },
              scales: {
                x: {
                  ticks: {
                    font: {
                      family: "'Schoolbell', cursive",
                      size: 14,
                    },
                  },
                },
                y: {
                  ticks: {
                    font: {
                      family: "'Schoolbell', cursive",
                      size: 14,
                    },
                  },
                },
              },
            }} />
          </Box>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleCloseGraphDialog}>Close</StyledButton>
        </DialogActions>
      </InfoDialog>

      {openPopup && (
        <PopupDialog open={openPopup} onClose={handleClosePopup}>
          <DialogTitle>Transaction Successful!</DialogTitle>
          <DialogContent>
            <Box id="popup-details">
              <Typography variant="body1" style={{ fontFamily: "'Schoolbell', cursive" }}><strong>Transaction Hash:</strong> {popupDetails.txHash}</Typography>
              <Typography variant="body1" style={{ fontFamily: "'Schoolbell', cursive" }}><strong>Amount:</strong> {popupDetails.amount} {popupDetails.selectedAsset}</Typography>
              <Typography variant="body1" style={{ fontFamily: "'Schoolbell', cursive" }}><strong>Multiplier:</strong> x{popupDetails.multiplier}</Typography>
              <Typography variant="body1" style={{ fontFamily: "'Schoolbell', cursive" }}><strong>Selected Result:</strong> {popupDetails.selectedResult}</Typography>
              <Typography variant="body1" style={{ fontFamily: "'Schoolbell', cursive" }}><strong>Bet Title:</strong> {bet.title}</Typography>
              <img src="/ermo.jpg" alt="Ermo" />
            </Box>
          </DialogContent>
          <DialogActions>
            <StyledButton onClick={handleDownload}>Download as JPG</StyledButton>
            <StyledButton onClick={handleClosePopup}>Close</StyledButton>
          </DialogActions>
        </PopupDialog>
      )}
    </BetCard>
  );
};

export default PlaceBet;
