export const betInfo = [
 
  {
    id: 1,
    type: 'Crypto',
    title: 'Bitcoin Price Prediction',
    description: 'Predict Bitcoins price range! Place your bet on where Bitcoin, price will be at the end of the week at 23:59 UTC.',
    result: ['Below 60k', '60-62k', '62-64k','64-66k', '66-68k', 'Abobe 68k'],
    header: 'Bitcoin Betting',
    startDate: '2024-10-14T00:00:00+02:00',
    endDate: '2024-10-18T23:59:59+02:00',
    profilePicture: `${process.env.PUBLIC_URL}/bitcoin.png`, // Example profile picture path
    walletAddress: 'addr1q8z5ht3zyralln2jer20ct9u2cu8gdtd20cc0j59l2sz2ncrm00kzlaetdrhccdmvucnlnqsn88cyz3p495hsuzs0h7sc7t0eg',
  }, 
];
