import React, { useState, useEffect } from 'react';
import { Button, Typography, Grid, AppBar, Toolbar, Select, MenuItem, FormControl, Menu, useMediaQuery, IconButton, Dialog, DialogContent, DialogTitle, TextField, Box } from '@mui/material';
import { styled } from '@mui/system';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Lucid, Blockfrost } from 'lucid-cardano';
import PlaceBet from './PlaceBet'; // Import PlaceBet component
import { betInfo } from './betInfo'; // Import betInfo
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'; // Custom ERMO AI icon

// Add styling for the cartoony UI
const CartoonyTitle = styled(Typography)(({ theme }) => ({
  fontSize: '3rem',
  fontFamily: "'Schoolbell', cursive",
  color: '#FF4500',
  margin: '20px 0',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
}));

const TopBar = styled(AppBar)(({ theme }) => ({
  background: 'linear-gradient(90deg, #FF4500, #FFD700)',
  borderBottom: '3px solid black',
  boxShadow: '0 4px 0px #000',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1200,
  width: '100%',
  padding: '10px 0',
  [theme.breakpoints.down('sm')]: {
    padding: '5px 0',
  },
}));

const TopBarTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "'Schoolbell', cursive",
  fontSize: '2.5rem',
  flexGrow: 1,
  color: '#FFFFFF',
  textShadow: '2px 2px 0px #000',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

const TopBarFilter = styled(FormControl)(({ theme }) => ({
  marginLeft: '20px',
  backgroundColor: '#FFFFFF',
  borderRadius: '10px',
  boxShadow: '4px 4px 0px #000',
  border: '2px solid black',
  '& .MuiSelect-select': {
    padding: '8px 16px',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '5px',
    '& .MuiSelect-select': {
      padding: '6px 10px',
    },
  },
}));

const TopBarButton = styled(Button)(({ theme }) => ({
  marginRight: '20px',
  backgroundColor: '#FFD700',
  color: '#000',
  fontFamily: "'Schoolbell', cursive",
  border: '2px solid black',
  boxShadow: '4px 4px 0px #000',
  '&:hover': {
    backgroundColor: '#FF4500',
    color: '#FFFFFF',
  },
  [theme.breakpoints.down('sm')]: {
    marginRight: '5px',
    fontSize: '0.7rem',
    padding: '4px 8px',
  },
}));

const BalanceDisplay = styled(Box)(({ theme }) => ({
  backgroundColor: '#FFD700',
  border: '3px solid black',
  borderRadius: '5px',
  boxShadow: '4px 4px 0px #000',
  padding: '10px',
  margin: '10px 0',
  textAlign: 'center',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    padding: '8px',
    margin: '5px 0',
  },
}));

const BalanceText = styled(Typography)(({ theme }) => ({
  fontFamily: "'Schoolbell', cursive",
  flex: 1,
  borderRight: '2px solid black',
  padding: '0 10px',
  '&:last-child': {
    borderRight: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
    padding: '0 5px',
  },
}));

const ChatBubbleButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  bottom: '20px',
  right: '20px',
  backgroundColor: '#FFD700',
  color: '#000',
  fontFamily: "'Schoolbell', cursive",
  border: '2px solid black',
  boxShadow: '4px 4px 0px #000',
  '&:hover': {
    backgroundColor: '#FF4500',
    color: '#FFFFFF',
  },
  [theme.breakpoints.down('sm')]: {
    bottom: '10px',
    right: '10px',
  },
}));

const AppContainer = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  backgroundImage: 'url(/background.png)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: '100px 20px 20px',
  [theme.breakpoints.down('sm')]: {
    padding: '80px 10px 10px',
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '20px',
    border: '3px solid black',
    boxShadow: '8px 8px 0px #000',
    transform: 'scale(1)', // Ensures no scale effect on hover
    width: 'calc(100% - 40px)',
    height: 'calc(100% - 40px)',
    maxWidth: 'none',
    margin: '20px',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)',
      height: 'calc(100% - 20px)',
      margin: '10px',
    },
  },
}));

const ChatDialogContent = styled(DialogContent)(({ theme }) => ({
  position: 'relative',
  height: 'calc(100% - 72px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingBottom: '16px',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: '8px',
  },
}));

const theme = createTheme();

const App = () => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [lucid, setLucid] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState('All');
  const [walletAnchorEl, setWalletAnchorEl] = useState(null);
  const [availableWallets, setAvailableWallets] = useState([]);
  const [chatOpen, setChatOpen] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatInput, setChatInput] = useState('');
  const [balances, setBalances] = useState({ ADA: 0, USDM: 0, ERMO: 0, SNEK: 0 });
  const isMobile = useMediaQuery('(max-width:600px)');

  const blockfrostApiKey = 'mainnetKsDvlA00TQW8E0FVTYIto5WWz93lS5Xe'; // Ensure this API key is correct

  const chatGptApiKey = 'sk-XHkhIjnoeapfVBhBpEsfpW2YXjxKlXhV7hlcvmdLctT3BlbkFJLYla-H8sMbQrvJ1BTKzt-JLMOsbxzSCowUL3492tAA';

  useEffect(() => {
    // Fetch available wallets (assuming wallets like nami, eternl, etc. are available)
    if (window.cardano) {
      const wallets = Object.keys(window.cardano).filter((key) => window.cardano[key].enable);
      const capitalizedWallets = wallets.map(wallet => wallet.charAt(0).toUpperCase() + wallet.slice(1));
      setAvailableWallets(capitalizedWallets);
    }
  }, []);

  useEffect(() => {
    if (lucid && walletAddress) {
      fetchBalances();
    }
  }, [lucid, walletAddress]);

  const fetchBalances = async () => {
    try {
      const utxos = await lucid.wallet.getUtxos();
      let adaBalance = 0;
      let usdmBalance = 0;
      let ermoBalance = 0;
      let snekBalance = 0;

      utxos.forEach((utxo) => {
        adaBalance += parseInt(utxo.assets['lovelace'] || 0) / 1000000;

        Object.entries(utxo.assets).forEach(([key, amount]) => {
          if (key.includes('c48cbb3d5e57ed56e276bc45f99ab39abe94e6cd7ac39fb402da47ad')) {
            usdmBalance += parseInt(amount);
          } else if (key.includes('e1a212122b07460a739af69751bd01cd03174791f778b2365cc0b8f6')) {
            ermoBalance += parseInt(amount);
          } else if (key.includes('279c909f348e533da5808898f87f9a14bb2c3dfbbacccd631d927a3f')) {
            snekBalance += parseInt(amount);
          }
        });
      });

      setBalances({
        ADA: adaBalance,
        USDM: usdmBalance,
        ERMO: ermoBalance,
        SNEK: snekBalance,
      });
    } catch (error) {
      console.error('Error fetching balances:', error);
    }
  };

  const connectWallet = async (walletKey) => {
    if (!walletKey) {
      console.error('No wallet selected');
      return;
    }

    try {
      const api = await window.cardano[walletKey.toLowerCase()].enable();
      const lucidInstance = await Lucid.new(
        new Blockfrost('https://cardano-mainnet.blockfrost.io/api/v0', blockfrostApiKey)
      );
      lucidInstance.selectWallet(api);

      const address = await lucidInstance.wallet.address();
      setLucid(lucidInstance);
      setWalletAddress(address);
    } catch (error) {
      console.error('Error connecting wallet:', error);
      alert('Failed to connect wallet. Please try again.');
    }
  };

  const disconnectWallet = () => {
    setWalletAddress(null);
    setLucid(null);
    setBalances({ ADA: 0, USDM: 0, ERMO: 0, SNEK: 0 });
  };

  const handleWalletMenuOpen = (event) => {
    setWalletAnchorEl(event.currentTarget);
  };

  const handleWalletMenuClose = () => {
    setWalletAnchorEl(null);
  };

  const handleWalletSelect = (walletKey) => {
    handleWalletMenuClose();
    connectWallet(walletKey);
  };

  const handleChatBubbleClick = () => {
    alert('Ermo AI free for a limited time!');
    setChatOpen(true);
    initializeChat();
  };

  const handleChatClose = () => {
    setChatOpen(false);
  };

  const handleChatInputChange = (event) => {
    setChatInput(event.target.value);
  };

  const initializeChat = async () => {
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${chatGptApiKey}`,
        },
        body: JSON.stringify({
          model: 'gpt-4o-mini',
          messages: [
            { role: 'system', content: "You are ERMO AI, a confident, risk-taking betting advisor. You provide direct, assertive recommendations to users, helping them maximize their betting potential without holding back." },
            { role: 'system', content: `Here is some context about the current webpage: ${document.documentElement.innerText}` },
            { role: 'system', content: `Currently available bets: ${betInfo.map(bet => bet.title).join(', ')}` },
            { role: 'system', content: `Betting results and statistics: ${betInfo.map(bet => `${bet.title} - Results: ${bet.result?.join(', ') || 'N/A'}`).join('; ')}` }
          ],
          max_tokens: 400,
        }),
      });

      const data = await response.json();
      const botMessage = data.choices[0].message.content.trim();

      setChatMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'bot', text: botMessage },
      ]);
    } catch (error) {
      console.error('Error initializing chat with ERMO AI:', error);
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'bot', text: 'Sorry, something went wrong initializing the chat. Please try again later.' },
      ]);
    }
  };

  const handleSendMessage = async () => {
    if (chatInput.trim() !== '') {
      const userMessage = chatInput;
      setChatMessages([...chatMessages, { sender: 'user', text: userMessage }]);
      setChatInput('');

      try {
        const response = await fetch('https://api.openai.com/v1/chat/completions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${chatGptApiKey}`,
          },
          body: JSON.stringify({
            model: 'gpt-4o-mini',
            messages: [
              { role: 'system', content: "You are ERMO AI, a confident, risk-taking betting advisor. You provide direct, assertive recommendations to users, helping them maximize their betting potential without holding back." },
              { role: 'system', content: `Here is some context about the current webpage: ${document.documentElement.innerText}` },
              { role: 'system', content: `Currently available bets: ${betInfo.map(bet => bet.title).join(', ')}` },
              { role: 'system', content: `Betting results and statistics: ${betInfo.map(bet => `${bet.title} - Results: ${bet.result?.join(', ') || 'N/A'}`).join('; ')}` },
              ...chatMessages.map((msg) => ({ role: msg.sender === 'user' ? 'user' : 'assistant', content: msg.text })),
              { role: 'user', content: userMessage },
            ],
            max_tokens: 150,
          }),
        });

        const data = await response.json();
        const botMessage = data.choices[0].message.content.trim();

        setChatMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'bot', text: botMessage },
        ]);
      } catch (error) {
        console.error('Error fetching response from ChatGPT:', error);
        setChatMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'bot', text: 'Sorry, something went wrong. Please try again later.' },
        ]);
      }
    }
  };

  // Extract unique bet types from betInfo
  const betTypes = ['All', ...new Set(betInfo.map((bet) => bet.type))];

  // Filter betInfo based on selectedTopic
  const filteredBets = selectedTopic === 'All' ? betInfo : betInfo.filter(bet => bet.type === selectedTopic);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppContainer>
        {/* Top Bar */}
        <TopBar position="static">
          <Toolbar style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
            {/* Filter on the left */}
            <TopBarFilter>
              <Select
                value={selectedTopic}
                onChange={(e) => setSelectedTopic(e.target.value)}
                displayEmpty
              >
                {betTypes.map((type) => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
              </Select>
            </TopBarFilter>

            {/* Title in the center */}
            <TopBarTitle>ErmoBets</TopBarTitle>

            {/* Connect/Disconnect Wallet dropdown button on the right */}
            {walletAddress ? (
              <TopBarButton onClick={disconnectWallet}>
                Disconnect {isMobile ? `${walletAddress.slice(0, 4)}...` : `${walletAddress.slice(0, 8)}...${walletAddress.slice(-4)}`}
              </TopBarButton>
            ) : (
              <>
                <TopBarButton onClick={handleWalletMenuOpen}>
                  Connect Wallet
                </TopBarButton>
                <Menu
                  anchorEl={walletAnchorEl}
                  open={Boolean(walletAnchorEl)}
                  onClose={handleWalletMenuClose}
                  PaperProps={{
                    style: {
                      border: '2px solid black',
                      boxShadow: '4px 4px 0px #000',
                      fontFamily: "'Schoolbell', cursive",
                    },
                  }}
                >
                  {availableWallets.map((wallet) => (
                    <MenuItem key={wallet} onClick={() => handleWalletSelect(wallet)}>
                      {wallet}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </Toolbar>
        </TopBar>

        {/* Balance Display */}
        {walletAddress && (
          <BalanceDisplay>
            <BalanceText>ADA: {balances.ADA.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</BalanceText>
            <BalanceText>USDM: {(balances.USDM / 1000000).toLocaleString(undefined, { minimumFractionDigits: 6, maximumFractionDigits: 6 })}</BalanceText>
            <BalanceText>ERMO: {balances.ERMO.toLocaleString()}</BalanceText>
            <BalanceText>SNEK: {balances.SNEK.toLocaleString()}</BalanceText>
          </BalanceDisplay>
        )}

        {/* Main Content */}
        <Grid container spacing={4} justifyContent="center" style={{ marginTop: isMobile ? '20px' : '40px' }}>
          {filteredBets.map((bet) => (
            <Grid item key={bet.id} xs={12} sm={10} md={8} lg={6}>
              <PlaceBet bet={bet} lucid={lucid} walletAddress={walletAddress} />
            </Grid>
          ))}
        </Grid>

        {/* Chat Bubble Button */}
        <ChatBubbleButton onClick={handleChatBubbleClick}>
          <EmojiPeopleIcon />
        </ChatBubbleButton>

        {/* Chat Dialog */}
        <StyledDialog open={chatOpen} onClose={handleChatClose} fullWidth maxWidth="lg">
          <IconButton onClick={handleChatClose} style={{ position: 'absolute', top: '10px', right: '10px', color: '#000' }}>X</IconButton>
          <DialogTitle style={{ fontFamily: "'Schoolbell', cursive", backgroundColor: '#FFD700', borderBottom: '3px solid black', boxShadow: '0 4px 0px #000' }}>Chat with ERMO AI</DialogTitle>
          <ChatDialogContent>
            <div style={{ overflowY: 'auto', flexGrow: 1, marginBottom: '16px' }}>
              {chatMessages.map((message, index) => (
                <div key={index} style={{ textAlign: message.sender === 'user' ? 'right' : 'left', margin: '8px 0' }}>
                  <Typography
                    variant="body1"
                    style={{
                      display: 'inline-block',
                      backgroundColor: message.sender === 'user' ? '#FFD700' : '#FF4500',
                      color: '#000',
                      padding: '8px 12px',
                      borderRadius: '10px',
                      boxShadow: '2px 2px 0px #000',
                    }}
                  >
                    {message.text}
                  </Typography>
                </div>
              ))}
            </div>
            <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
              <TextField
                variant="outlined"
                fullWidth
                value={chatInput}
                onChange={handleChatInputChange}
                placeholder="Type your message..."
                InputProps={{
                  style: {
                    fontFamily: "'Schoolbell', cursive",
                    boxShadow: '2px 2px 0px #000',
                  },
                }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSendMessage}
                style={{ backgroundColor: '#FF4500', color: '#FFFFFF', boxShadow: '2px 2px 0px #000', fontFamily: "'Schoolbell', cursive" }}
              >
                Send
              </Button>
            </div>
          </ChatDialogContent>
        </StyledDialog>
      </AppContainer>
    </ThemeProvider>
  );
};

export default App;
